<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols=12 md=3 lg=2 
                v-if="!isMobileSize() || selected == null"
                class="pa-0"
            >
                <FeatureList />
            </v-col>
            <v-col 
                cols="6"
                :class="isMobileSize() ? 'pa-0' : 'pb-16'"
            >
                <FeatureEditor v-if="selected" :propertiesAreSelectable="true" class="pr-0"/>
                <v-card 
                    v-else-if="!isMobileSize()"
                >
                    <v-card-text>{{ $t('FeatureEditor.select') }}</v-card-text>
                </v-card>
            </v-col>
            <v-col cols="3">
                <StoreEditorSettings />
                
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import FeatureList from '@/components/FeatureList'
    import FeatureEditor from '@/components/FeatureEditor'
    import StoreEditorSettings from '@/components/StoreEditorSettings'

    export default {
        components: {
            FeatureList,
            FeatureEditor,
            StoreEditorSettings
        },
        computed: {
            selected() {
                return this.$store.getters.selected
            },
        }        
    }
</script>