export default async function readAndParseJSONFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        // Define an event handler for when the file reading is complete
        reader.onload = (event) => {
            try {
                const jsonString = event.target.result;
                const jsonData = JSON.parse(jsonString);
                resolve(jsonData);
            } catch (error) {
                reject(error);
            }
        };

        // Define an event handler for when there's an error reading the file
        reader.onerror = () => {
            reject(new Error('Error reading the file.'));
        };

        // Read the file as text
        reader.readAsText(file);
    });
}