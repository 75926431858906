<template>
    <v-card>
        <v-card-title>
            Template properties
            <v-spacer/>
            <v-btn 
                icon
            >   
                <v-icon @click="showConfigImport = !showConfigImport">mdi-import</v-icon>
            </v-btn>
            <Dialog 
                v-model="showConfigImport"
                title="Import configuration"
            >
                <v-file-input 
                    v-model="file" 
                    accept=".json"
                    placeholder=".json configuration file"
                ></v-file-input>
            </Dialog>
        </v-card-title>
        <v-card-text>
            <v-form
                ref="form"
                v-model="formIsValid"
                lazy-validation
            >
                <v-select
                    v-model="form.category"
                    :rules="categoryRules"
                    :items="formattedCategories"
                    item-text="title.en"
                    item-value="category"
                    label="Category"
                    class="pb-4"
                    filled
                    hide-details="auto"
                ></v-select>
                <v-text-field
                    v-model="form.title.en"
                    :rules="nameRules"
                    label="Name"
                    class="pb-4"
                    filled
                    hide-details="auto"
                ></v-text-field>
                <v-textarea
                    v-model="form.summary.en"
                    :rules="summaryRules"
                    label="Summary"
                    class="pb-4"
                    rows="3"
                    filled
                    hide-details="auto"
                ></v-textarea>
                <v-textarea
                    v-model="form.description.en"
                    :rules="descriptionRules"
                    label="Description"
                    class="pb-4"
                    filled
                    hide-details="auto"
                ></v-textarea>
                <v-text-field
                    v-model="form.image_url"
                    :rules="imageUrlRules"
                    label="(Optional) Image URL"
                    class="pb-4"
                    filled
                    hide-details="auto"
                >
                </v-text-field>
                <VersionSelector 
                    hide-info
                    disabled   
                />
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-row dense>
                <v-col>
                    <v-btn
                        :disabled="!formIsValid" 
                        @click="validateForm"
                        color="primary"
                        block
                    >
                        Validate template
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn
                        @click="showTemplateOverview = true"
                        block
                    >
                        Show overview
                        <Dialog
                            v-model="showTemplateOverview"
                            title="Template overview"
                            width="40%"
                        >
                            <span class="text--h4 font-weight-bold">Image preview</span>{{' | 150x250 aspect ratio recommended'}}
                            <div v-if="!imageLoaded">
                                <p v-if="form.category">
                                    Unable to load image, please check URL. Resorting to placeholder.
                                </p>
                                <p v-else class="error--text">
                                    Unable to load image, please check URL.
                                </p>
                            </div>
                            
                            <v-img
                                v-if="form.category && !imageLoaded"
                                :src="storeCategories[form.category].placeholder_image_url"     
                                class="mx-auto mb-4"
                                height="150"
                                width="250"                       
                            />
                            <v-img
                                v-model="image"
                                class="mx-auto mb-4"
                                height="150"
                                width="250"
                                :src="form.image_url"
                                hide-details="auto"
                                @error="imageLoaded = false"
                                @load="imageLoaded = true"
                                v-show="imageLoaded"
                            />
                            
                            <h4>Configuration overview</h4>
                            <JSONTreeview 
                                v-if="anyPropertySelected" 
                                :data="storeEditorConfig"
                            />
                            <p v-else>No properties selected</p>
                            <h4>Template YAML</h4>
                            <v-textarea
                                v-if="templateYaml"
                                v-model="templateYaml"
                                readonly
                                filled
                                hide-details="auto"
                            >
                                <template v-slot:append-outer>
                                    <v-btn icon @click="copyToClipboard(templateYaml)">
                                        <v-icon>mdi-content-copy</v-icon>
                                    </v-btn>
                                </template>

                            </v-textarea>
                            <p v-else>Unable to load template</p>
                        </Dialog>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import { isEmpty } from 'lodash'

import VersionSelector from '@/components/VersionSelector.vue'
import Dialog from '@/components/dialogs/Dialog.vue'
import JSONTreeview from '@/components/JSONTreeview.vue'
import copyToClipboard from '@/utils/copyToClipboard.js'
import readAndParseJSONFile from '@/utils/readAndParseJSONFile.js'
import getMissingPropertiesFromConfiguration from '@/utils/getMissingPropertiesFromConfiguration.js';

import yaml from 'js-yaml'

export default {
    components: {
        VersionSelector,
        Dialog,
        JSONTreeview
    },
    data: () => ({
        file: null,
        form: {
            category: null,
            title: {
                en: null
            },
            summary: {
                en: null
            },
            description: {
                en: null
            },
            image_url: null
        },
        formIsValid: true,
        categoryRules: [
            v => !!v || 'Category is required',
        ],
        nameRules: [
            v => !!v || 'Title is required',
            v => (v && v.length <= 50) || 'Title cannot be longer than 50 characters',
        ],
        summaryRules: [
            v => !!v || 'Summary is required',
            v => (v && v.length <= 256) || 'Summary cannot be longer than 256 characters',
        ],
        descriptionRules: [
            v => !!v || 'Description is required',
            v => (v && v.length <= 500) || 'Description cannot be longer than 500 characters',
        ],
        imageUrlRules: [
            v => (!v || v.length <= 256) || 'Image URL cannot be longer than 256 characters',
            v => (!v || /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g.test(v)) || 'Invalid image URL'
        ],
        showConfigImport: false,
        showTemplateOverview: false,
        imageLoaded: false,
        image: null
    }),
    computed: {
        features() {
            return this.$store.getters.features
        },  
        versionRequirements() {
            return this.$store.getters.versions
        },
        featureNamesInDesired() {
            return this.$store.getters.featureNamesInDesired
        },
        storeEditorConfig() {
            return this.$store.getters.storeEditorConfig
        },
        storeCategories() {
            return this.$store.getters.storeCategories
        },
        formattedCategories() {
            if(!this.storeCategories) { 
                return [] 
            }
            return Object.entries(this.storeCategories).map(([category, properties]) => {
                return {category, ...properties}
            })
        },
        anyPropertySelected() {
            return !isEmpty(this.$store.getters.storeEditorConfig)
        },
        templateYaml() {
            let template = [{
                id: null,
                ...this.form, 
                config: this.storeEditorConfig, 
                min_version: this.versionRequirements
            }]
           return yaml.dump(template, {
                'styles': {
                    '!!null': 'empty'
                },    
                'lineWidth': -1
           })
        },
        
    },
    methods: {
        validateForm() {
            // if(this.$refs.form.validate()) {
            //     // TODO: save changes
            // }
            this.$refs.form.validate()
        },
        copyToClipboard(text) {
            return copyToClipboard(text)
        },
        async importConfig() {
            let config = await readAndParseJSONFile(this.file)
            let missingProperties = getMissingPropertiesFromConfiguration(config, this.features)
            this.$store.dispatch("setMissingProperties", missingProperties)
            this.$store.dispatch("setDesired", config)
            this.featureNamesInDesired.forEach(featureName => {
                this.$store.dispatch("cleanDesired", featureName)
            })
        },
    },
    watch: {
      file(val) {
        if(val) {
            this.importConfig()
            this.showConfigImport = false
        }
      }
    }
}
</script>